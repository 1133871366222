import { createSlice } from "@reduxjs/toolkit";
import { addOneOrder,getAllOrders,updateOrder , getAllOrdersWithPagination, getNewOrderOnlineSerial } from "./thunk";

const initialState={
    orders:[],
    ordersWithPagination:[],
    isLoading:false,
    newSerial:null
};

const ordersSlice=createSlice({
    initialState,
    name:"ordersSlice",
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(addOneOrder.fulfilled,(state,action)=>{
            state.orders=[...state.orders,action.payload];
            state.isLoading=false;

        });
        builder.addCase(addOneOrder.pending,(state,action)=>{
            state.isLoading=true;
        });
        builder.addCase(getAllOrders.fulfilled,(state,action)=>{
            state.orders=action?.payload;
        });
        builder.addCase(updateOrder.fulfilled,(state,action)=>{

           // console.log('action.payload',action.payload);

            // state.ordersWithPagination.data = state?.ordersWithPagination?.data?.map((order) =>
            //     order?._id.toString() === action.payload?._id.toString()
            //       ? { ...order, ...action.payload }
            //       : order
            //       );
        });

        builder.addCase(getAllOrdersWithPagination.fulfilled,(state,action)=>{
           // console.log('action?.payload',action?.payload);
            state.ordersWithPagination=action?.payload;
        });
        builder.addCase(getNewOrderOnlineSerial.fulfilled,(state,action)=>{
           // console.log('action?.payload',action?.payload);
            state.newSerial=action?.payload;
        });
      
    }
});

export default ordersSlice.reducer;