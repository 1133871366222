import { createSlice } from "@reduxjs/toolkit";
import { getAllWithDrawsForAccountant , updateWithDraw, EmptyWithDraws } from "./thunk";

const initialState={
    withDraws:[],
    updatedWithDraw:{}
};

const withDrawsSlice=createSlice({
    initialState,
    name:"withDrawsSlice",
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getAllWithDrawsForAccountant.fulfilled,(state,action)=>{
            state.withDraws=action?.payload;
        });
        builder.addCase(EmptyWithDraws.fulfilled,(state,action)=>{
            state.updatedWithDraw={};
        });
        builder.addCase(updateWithDraw.fulfilled,(state,action)=>{
            state.withDraws = state?.withDraws?.map((withDraw) =>
                withDraw?._id.toString() === action.payload?._id.toString()
                  ? { ...withDraw, ...action.payload }
                  : withDraw
                  );
                  state.updatedWithDraw =action?.payload
        });
    }
});

export default withDrawsSlice.reducer;