import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAllCities, getAllCountries } from "../../redux/slices/cities/thunk";
import { addOneUser, getAllUsers, updateOneUser, getOneUser } from "../../redux/slices/users/thunk";
import { getAllPermissionGroups } from "../../redux/slices/permissions/thunk";
import { getAllOffers } from "../../redux/slices/offers/thunk";
import notify from "../useNotification";


export default function AddAndEditUserHook() {
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [row, setRow] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [selectedOffer, setSelectedOffer] = useState([]);
    const [comission, setComission] = useState('');
    const [selectedPermissionsIDS, setSelectedPermissionsIDS] = useState(location?.state?.permissions ? location?.state?.permissions : []);
    const [selectedInheritancePermissionsIDS, setSelectedInheritancePermissionsIDS] = useState(location?.state?.permissions_inheritance ? location?.state?.permissions_inheritance : []);
    const [offersPanned, setOffersPanned] = useState(location?.state?.offers_banned ? location?.state?.offers_banned : []);
    // const [state,setState]= useState(location?.state ? location?.state?.supervisors_list : '' )
    // const [mobile, setMobile] = useState(
    //     location?.state?.mobile ? location?.state?.mobile : ''
    // );
    const firstRenderRef = useRef(true);

    const loggedUser = JSON.parse(localStorage.getItem('user'));

    const{liquidationUsers}=useSelector(state=>state.usersReducer);
    const user=useSelector(state=>state.permissionsReducer.userScreens);

    
    console.log("location?.state?.printing_possibility",location?.state?.printing_possibility)
    //------------
    const hasLowerCase = /[a-z]/;
    const hasNumber = /\d/;
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/;
    const hasUpperCase = /[A-Z]/;
        console.log("hasUpperCase",hasUpperCase)
    
        
    //------------
    const [passwordErrorMassage, setPasswordErrorMassage] = useState(false);

    const { oneUser } = useSelector((state) => state.usersReducer);
console.log("oneUser",oneUser)



    let initialValues = {
        serialNumber: location?.state?.serialNumber || "",
        fullname: location?.state?.fullname || "",
        username: location?.state?.username || "",
        password: location?.state?.password || "",
        email: location?.state?.email || "",
        mobile: location?.state?.mobile || "",
        country: loggedUser?.isAdmin? location?.state?.country?._id : loggedUser?.country?._id || 0,
        city: location?.state?.city?._id || 0,
        number_of_supervisors_allowed: location?.state?.number_of_supervisors_allowed || "",
        wallet_credit_limit: location?.state?.wallet_credit_limit || "",
        

        // permissions:location?.state?.permissions.length>0 ? location?.state?.permissions : permissionGroups[0]?._id ,
        number_of_marketers_allowed: location?.state?.number_of_marketers_allowed ? location?.state?.number_of_marketers_allowed : 0,
        custody: location?.state?.custody || "",
        isAdmin: location?.state?.isAdmin !==null ? location?.state?.isAdmin : false,
        liquidation_user: location?.state?.liquidation_user ? location?.state?.liquidation_user : false,
        userMarketer: location?.state?.userMarketer ? location?.state?.userMarketer : false,
        is_accountant: location?.state?.is_accountant  ? location?.state?.is_accountant : false,
        receipt_user:location?.state?.receipt_user ? location?.state?.receipt_user : false,
        printing_possibility: location?.state?.printing_possibility !=null  ? location?.state?.printing_possibility : false,
        receipt_user: location?.state?.receipt_user  ? location?.state?.receipt_user : false,
        father_id:location?.state?.father_id ? location?.state?.father_id : 0,
        supervisors_list:location?.state?.supervisors_list ? location?.state?.supervisors_list : [],
        status: location?.state?.status !=null  ? location?.state?.status : true,
        show_mobile_in_users_card_table: location?.state?.show_mobile_in_users_card_table !==null ?
        location?.state?.show_mobile_in_users_card_table : false,

    };



    // useEffect(() => {
    //     if (isLoading === false && (updatedUser?._id || addUser?._id)) {
    //         console.log('Redirecting to /users');
    //         navigate('/users');
    //     }
    // }, [isLoading, updatedUser, addUser]);


    
    console.log("location",location)
    console.log("loggedUser",loggedUser)

       
    const onSubmit = async (values, { resetForm }) => {


        values.email = values.email.replace(/\s+/g, '');

       
        
        //--------------------------------------------------------
//         if (isSubmitting) return; 
//     setIsSubmitting(true); 
  
//     let counter = 1;
//     if (counter > 1) {
//       setIsSubmitting(false);
//       return;
//     }
//     counter++;
// console.log("counter",counter)
// console.log("counterState",isSubmitting)
        //--------------------------------------------------------
        console.log("firstSumit")
        
        let currentValues = {
            ...values, permissions: selectedPermissionsIDS ,
            permissions_inheritance: selectedPermissionsIDS 
            , offers_banned: offersPanned
        };
        console.log("firstSumit2")
console.log("currentValues",currentValues)
// return;
console.log("Form values:", values);
console.log("Selected Permissions:", selectedPermissionsIDS);
console.log("Offers Banned:", offersPanned);
if(loggedUser?.isAdmin || loggedUser?.is_accountant){

    if (!currentValues?.isAdmin && selectedPermissionsIDS.length === 0) {
        console.log("first")
        return notify("يجب اختيار صلاحيات المستخدم", "error");
    }
}
console.log("users?.length > (user?.number_of_supervisors_allowed)",oneUser?.supervisors_list?.length > (user?.number_of_supervisors_allowed))
console.log("users for arr :?.length",oneUser?.supervisors_list?.length )
console.log("users for arr :",oneUser)
console.log("user?.number_of_supervisors_allowed",user?.number_of_supervisors_allowed)

// return;
        if(loggedUser?.isAdmin){
             // المستخدم يكون ليه صلاحية واحدة
        let count_permissions=0;

        if(currentValues?.liquidation_user) count_permissions++;
        if(currentValues?.userMarketer) count_permissions++;
        if(currentValues?.isAdmin) count_permissions++;
        if(currentValues?.is_accountant) count_permissions++;
        
        if(count_permissions==0) return notify(" يجب اختيار نوع المستخدم ","error");
        if(count_permissions>1) return notify(" يجب ان يكون المستخدم ادمن او مندوب او مسوق فقط","error");
        }
       
if((formik.values.country == "66c33878e67a6fe5f149fdec" && loggedUser?.isAdmin) || (loggedUser?.country?._id == "66c33878e67a6fe5f149fdec" && loggedUser?.liquidation_user)){
    if (values.mobile.length != 10) {
        // setMobileError(true);
   return notify("برجاء مراجعة رقم الجوال", "error");
}   
}
          //------------------
 
            //------------------

        //   return;

        if (currentValues.city == 0 ) return notify("من فضلك اختر المدينة والبلد", "error");
        let rowsArr = [];

        row.map(el => {

            let row = { offer: el?.offer?._id, commission: el?.commission, id: el?._id }
            rowsArr.push(row);
        });

        currentValues.offers_commission_user = rowsArr;

        if (!loggedUser?.isAdmin) currentValues.number_of_marketers_allowed = 0;

        console.log('on submit values');
        console.log(currentValues);

        // مندوب
        if (loggedUser.liquidation_user) {
            console.log('users?.length', users?.length);
            //users.length
            if (oneUser?.supervisors_list?.length  >= (user?.number_of_supervisors_allowed)) {
                return notify("لقد تخطيت عدد المشرفين الخاص بك", "error");
            }

            currentValues.father_id = loggedUser._id;
            currentValues.number_of_supervisors_allowed = 0;
            currentValues.userMarketer=true;
        }

        if(currentValues.father_id==0) delete currentValues.father_id

        // return;
        //edit
        if (id) {
            try {
                setIsSubmitting(true);
                await dispatch(updateOneUser({ id, data: currentValues }));
                navigate('/users'); 
            } catch (error) {
                console.error("Error updating user:", error);
            } finally {
                setIsSubmitting(false); 
            }
        } else {
            if (
                values.password.length < 8 || 
                !hasUpperCase.test(values.password) || 
                !hasLowerCase.test(values.password) || 
                !hasNumber.test(values.password) || 
                !hasSymbol.test(values.password)
            ) {
                setPasswordErrorMassage(true);
                return notify("يجب عليك إدخال كلمة مرور تحتوي على أحرف كبيرة وصغيرة ورقم ورمز يتكون من 8 أحرف على الأقل", "error");
            }
        
            setPasswordErrorMassage(false);
        
            try {
                setIsSubmitting(true);
                await dispatch(addOneUser({ data: currentValues }));
                navigate('/users'); 
            } catch (error) {
                console.error("Error adding user:", error);
                // notify("حدث خطأ أثناء إضافة البيانات. يرجى المحاولة مرة أخرى.", "error");
            } finally {
                setIsSubmitting(false); 
            }
        }
    }

    const formik = useFormik({
        initialValues,
        //validationschema here
        //enableReinitialize: true,
        onSubmit,
    });

    const handleEmailChange = (e) => {
        formik.setFieldValue('email', e.target.value);
    };

    console.log("getOneUser",loggedUser?._id)

    useEffect(() => {
        dispatch(getAllCountries());
        dispatch(getAllPermissionGroups());
        if (loggedUser?._id) {
            dispatch(getOneUser({ id: loggedUser._id }));
        }      
        let query=``;
        query += `type=offer`;
        dispatch(getAllOffers(query));
       // dispatch(getAllOffers());
        //offers_commission_user
        console.log("location111",location)
        if(!loggedUser.isAdmin){
            dispatch(getAllCities(loggedUser?.country?._id));
        } 

        if (id) {
          
            dispatch(getAllCities(location?.state?.country?._id));
            setRow(location?.state?.offers_commission_user);
        }
       
    }, [location?.state]);

    console.log('location?.state?.offers_commission_user',location?.state?.offers_commission_user);

    const { offers } = useSelector(state => state.offersReducer);
    

    console.log('offers',offers);

    // {offer,commission} salesCommission

    useEffect(()=>{
        if(id==null){
            console.log('offers use effect');
            let offersArr=[];

            offers?.map(el=>{
                let offerObj={};
                offerObj.offer=el;
                offerObj.commission=el?.salesCommission;
                offersArr.push(offerObj);
            });

            setRow(offersArr);
        }
        else{

        }
    },[offers]);

    console.log('row', row);

    const users = useSelector(state => state.usersReducer.users);
    const { cities, countries } = useSelector(state => state.citiesReducer);
    const { updatedUser, addUser, isLoading } = useSelector(state => state.usersReducer);
    let permissionGroups = useSelector(state => state.permissionsReducer.permissionGroups);

    console.log("permissionGroups",permissionGroups)
    
    permissionGroups = permissionGroups.filter(group => group.status === true);

    console.log('liquidation_user', loggedUser?.liquidation_user);
    // console.log('isLoading', isLoading);
    console.log('location?.state', location?.state);

    if (loggedUser?.liquidation_user || loggedUser?.is_accountant) {
        permissionGroups = loggedUser?.permissions_inheritance_group
    }


    console.log('formik', formik.values);
    console.log("formik.values.country",formik.values.country)



    // useEffect(() => {
    //     if (firstRenderRef.current) {
    //        // firstRenderRef.current = false;
    //         return;
    //     }

    //     if (isLoading === false && (updatedUser?._id || addUser?._id)) {
    //     navigate('/users');
    //     }
    // }, [isLoading]);

    useEffect(() => {
        //if(id) return;
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
            return;
        }

        let offersWithOutBlocked = [];
        // offersPanned.length == 0
        if (false) offersWithOutBlocked = offers;
        else {

            offersWithOutBlocked = offers?.filter(
                el => !offersPanned?.includes(el?._id)
            );

            console.log('offersWithOutBlocked', offersWithOutBlocked);
            // let foundOffer = row.find(ele => ele?.offer?._id == el?._id);

            offersWithOutBlocked = offersWithOutBlocked?.map(el => {

                //if (id) {
                let foundOffer = row.find(ele => ele?.offer?._id == el?._id);

                console.log('xxxxxxxxxxxxxxxxxx');
                console.log('foundOffer', foundOffer);
                console.log('xxxxxxxxxxxxxxxxxx');

                let offer=location?.state?.offers_commission_user?.find(ele=>ele?.offer?._id==el?._id);

                console.log('offerrrrrr',offer);
                if(offer){
                    return offer;
                }
                else{
                    return {
                        offer: foundOffer?.offer ? foundOffer?.offer : el,
                        commission: foundOffer?.commission ? foundOffer?.commission : el?.salesCommission
                    }
                }
               
                // }
                // else {
                //     return {
                //         offer: el,
                //         commission: el?.salesCommission
                //     }
                // }

            });

            setRow(offersWithOutBlocked);
        }

    }, [offersPanned]);

    // useEffect(() => {

    //     // if (firstRenderRef.current) {
    //     //     firstRenderRef.current = false;
    //     //     return;
    //     // }
    //     const maxLength = 10;
    //     let value = mobile.replace(/\D/g, '');
    //     if (value.length > maxLength) {
    //         value = value.slice(0, maxLength);
    //     }

    //     formik.setValues({
    //         ...formik.values,
    //         mobile
    //     });

    // }, [mobile]);


    const handleAddRow = () => {
        let foundOffer = row.find(el => el?.offer?._id == selectedOffer?._id);

        if (foundOffer?.offer?._id) return notify('لا يمكن اضافة اكثر من عرض لنفس المستخدم', "error");
        console.log('foundOffer', foundOffer);
        console.log('selectedOffer', selectedOffer);
        setRow(prev => [...prev, { offer: selectedOffer, commission: comission }]);
        setSelectedOffer('0');
        setComission('');

    };

    const onCountryChange = async (e) => {
        const selectedCountryId = e.target.value; // Get the selected country ID from the event
    
        try {
            console.log('Selected country ID:', selectedCountryId); // Log the selected country ID
            
            // Update Formik values with the new country ID
            formik.setValues(prevValues => ({
                ...prevValues,
                country: selectedCountryId // Set the country ID in Formik values
            }));
            if(!loggedUser?.isAdmin){
                await  dispatch(getAllCities(loggedUser?.country?._id));
            }
            // Fetch cities based on the selected country ID
            else{
                await dispatch(getAllCities(selectedCountryId));
            }
           
    
        } catch (error) {
            console.error('Error fetching cities:', error.message); // Handle any errors
        }
    };
    

    return [
        row, setRow, showModal, setShowModal, selectedOffer, setSelectedOffer, handleAddRow, formik,
        countries, cities, permissionGroups,
        selectedPermissionsIDS, setSelectedPermissionsIDS,
        selectedInheritancePermissionsIDS, setSelectedInheritancePermissionsIDS, onCountryChange,
        offers,
        comission, setComission
        , offersPanned, setOffersPanned,
        liquidationUsers,
        passwordErrorMassage,
        isSubmitting,
        handleEmailChange,
        
       // , mobile, setMobile
    ];

}
