import { Nav, NavDropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import profileImg from "../../Images/Frame.png";
import vector from "../../Images/Vector.png";
import { toggleDarkMode } from "../../redux/slices/darkModeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import logo from "../../Images/takafol-logo.png";
import NotificationMenu from "./NotificationMenu";
import baseURL from "../../Api/baseURL";
import { FaUserCircle } from "react-icons/fa";

export default function NavBarLogin() {
  const loggedInUser = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const darkModeEnabled = useSelector((state) => state.darkMode.isEnabled);
  const [notification, setNotification] = useState([]);
  console.log(darkModeEnabled);
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to track dropdown visibility

  const handleDarkModeToggle = () => {
    dispatch(toggleDarkMode());
  };
  useEffect(() => {
    if (darkModeEnabled) {
      document.documentElement.classList.add("dark-mode");
    } else {
      document.documentElement.classList.remove("dark-mode");
    }
  }, [darkModeEnabled]);
  const get_notify = async () => {
    const response = await baseURL.get(
      `/api/v1/notification?user_id=${loggedInUser._id}`
    );

    if (response) setNotification(response.data);
  };
  useEffect(() => {
    if (loggedInUser?._id) get_notify();
    return () => {};
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  if (pathname === "/") return;
  if (pathname === "/orders/new") return;
  if (pathname === "/sendOrderSuccessfully") return;
  if (pathname === "/failedToSendOrder") return;

  // رابط صفحة المسوق
  if (pathname == "/marketing/report") {
    if (!loggedInUser) return;
  }
  // التحقيق من العضوية
  if (pathname == "/check-member") {
    if (!loggedInUser) return;
  }
  if (pathname == "/SendOrderSuccessfully") {
    if (!loggedInUser) return;
  }
  if (pathname == "/failedToSendOrder") {
    if (!loggedInUser) return;
  }

  const logOut = () => {
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  const markAsRead = async (id) => {
    await baseURL.put(`/api/v1/notification/${id}`, {
      seen: true,
    });
    get_notify();
  };

  const handleDropdownToggle = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  const handleItemClick = (notification) => {
    markAsRead(notification._id);
    if (notification.type === "deposit") {
      navigate("/deposites");
    } else if (notification.type === "request_transfer") {
      navigate(`/orders?requestStatus=new_request`);
    } else if (notification.type === "request") {
      navigate(`/members`);
    } else if (notification.type === "wallet") {
      navigate(`/wallet`);
    } else if (notification.type === "liquidation") {
      navigate(`/liquidation?liquidation-type=new`);
    } else if (notification.type === "wallet_withdrawel") {
      navigate(`/withDrawRequests`);
    }

    // Add more conditions for other types if needed
  };
  console.log("xxxxxxxxxx", loggedInUser);

  return (
    <div className="navBar">
      <Link to={"/home"}>
        <div className="navbarrigth">
          <img src={logo} alt="" width="50px" height="50px" />
        </div>
      </Link>
      {pathname === "/orders/new" ||
      pathname === "/sendOrderSuccessfully" ||
      pathname === "/failedToSendOrder" ? (
        ""
      ) : (
        <div className="navbarImg">
          <div>
            {/* <img src={vector} alt="" /> */}
            <NotificationMenu
              notifications={notification}
              handleItemClick={handleItemClick}
              onToggle={handleDropdownToggle}
              dropdownOpen={dropdownOpen}
            />
          </div>

          <NavDropdown
            title={loggedInUser?.username}
            className="dropdownstyles"
            style={{ fontFamily: "Cairo", color: "var(--text-color)" }}
          >
            <NavDropdown.Item
              style={{
                textAlign: "center",
                fontWeight: "600",
                background: "var(--background-color)",
                color: "var(--text-color)",
              }}
              onClick={handleDarkModeToggle}
            >
              {darkModeEnabled ? "الوضع الفاتح" : "الوضع الداكن"}
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={() => navigate("/setting/profile?section=Profile")}
              style={{
                textAlign: "center",
                fontWeight: "600",
                background: "var(--background-color)",
                color: "var(--text-color)",
              }}
            >
              الملف الشخصي
            </NavDropdown.Item>
            {loggedInUser?.liquidation_user && (
              <NavDropdown.Item
                onClick={() => {
                  const url = `/orders/new?marketing_link_number=${loggedInUser?.marketing_link_number}&markter=${loggedInUser?._id}`;
                  window.open(url, "_blank");
                }}
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  background: "var(--background-color)",
                  color: "var(--text-color)",
                }}
              >
                رابط التسويق
              </NavDropdown.Item>
            )}
            <NavDropdown.Item
              onClick={() => logOut()}
              style={{
                textAlign: "center",
                fontWeight: "600",
                background: "var(--background-color)",
                color: "var(--text-color)",
              }}
            >
              تسجيل الخروج
            </NavDropdown.Item>
          </NavDropdown>
          <div>
            <FaUserCircle size={35} />
            {/* <img src={profileImg} alt="" /> */}
          </div>
        </div>
      )}
    </div>
  );
}
