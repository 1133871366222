import { useDispatch, useSelector } from "react-redux";
import HeadSection from "../Utilities/HeadSection";
import { useEffect } from "react";
import { getAllWithDrawsForAccountant, EmptyWithDraws } from "../../redux/slices/withDraws/thunk";
import WithDrawsTable from "./WithDrawsTable";
import { Navigate } from "react-router-dom";


export default function AccountantWithDrwasComponent() {
    const dispatch=useDispatch();
    const loggedUser= JSON.parse(localStorage.getItem('user'));
    const{withDraws}=useSelector(state=>state.withDrawsReducer);

    console.log("loggedUser")

    useEffect(()=>{
     

        let query=`accountant_id=${loggedUser?._id}`;
        dispatch(getAllWithDrawsForAccountant(query));

        return ()=>{
          dispatch(EmptyWithDraws())
        }
        
    },[]);
    
    if (!loggedUser?.is_accountant) {
      return <Navigate to='/wallet' />;
  }
  


  return (
    <div className="firstContainer mx-2">
        <HeadSection label={"طلبات السحب"} />
        <WithDrawsTable withDraws={withDraws} />
    </div>
  )
}
