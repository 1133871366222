import { createAsyncThunk } from "@reduxjs/toolkit";
import notify from "../../../hook/useNotification";
import { useInsertData } from "../../../hooks/useInsertData";
import { useGetDataToken } from "../../../hooks/useGetData";
import { useUpdateData } from "../../../hooks/useUpdateData";

export const addOneOrder = createAsyncThunk(
    "/ordersSlice/addOrder",
    async ({ data }, { rejectWithValue }) => {
        try {
            const response = await useInsertData("/api/v1/requests", data);
            notify("تم ارسال الطلب بنجاح", "success");
            return response; // Return the whole response for later use
        } catch (error) {
            if (error.message === "Network Error") {
                notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
                return rejectWithValue("Network Error");
            } else if (error.response) {
                console.log("error.response", error.response);
                notify(error.response.data || "حدث خطأ حاول مرة اخري", "error");
                return rejectWithValue(error.response.data); // Return the error response for handling in the component
            } else {
                notify('حدث خطأ حاول مرة اخري ', "error");
                return rejectWithValue('حدث خطأ حاول مرة اخري '); // Return a generic error message
            }
        }
    }
);


export const getAllOrders=createAsyncThunk(
    "/ordersSlice/allorders",
    async ({ query}, { rejectWithValue }) => {
        try {
            console.log('response.........');
            //requestStatus=${query}
            const response=await useGetDataToken(`/api/v1/requests?${query}`);   
            notify("تم تعديل الطلب بنجاح", "success");   
            return response;

        } catch (error) {
           
            if (error.message == "Network Error")
                {notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
               return rejectWithValue("Network Error")}
               else if (error.response) {
                   console.log("error.response", error.response);
                   notify(error.response.data || "حدث خطأ حاول مرة اخري", "error");
                   return rejectWithValue(error.response.data); // Return specific error response
               }else {
                   notify('حدث خطأ حاول مرة اخري ', "error");
                   return rejectWithValue('حدث خطأ حاول مرة اخري '); // Generic error message
               }
    }
}
);

export const updateOrder=createAsyncThunk(
    "/ordersSlice/updateOneOrder",
    async({id,data})=>{
        try {
            const response = await useUpdateData(`/api/v1/requests/${id}`, data);
            notify("تم تعديل الطلب بنجاح", "success");
            
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
            else
                return notify(error.message, "error");
        }
    }
);

export const getAllOrdersWithPagination=createAsyncThunk(
    "/ordersSlice/getAllOrdersWithPagination",
    async(query)=>{
        try {
            //requestStatus=${query}
            const response=await useGetDataToken(`/api/v1/requests?${query}`);      
            console.log('response.........', response);
            return response;

        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
        else
            return notify(error.message, "error");
        }
    }
    
);
export const getNewOrderOnlineSerial=createAsyncThunk(
    "/ordersSlice/getNewOrderOnlineSerial",
    async(query)=>{
        try {
            //requestStatus=${query}
            const response=await useGetDataToken(`/api/v1/requests/newSerial`);      
            console.log('response.........', response);
            return response;

        } catch (error) {
            if (error.message == "Network Error")
            return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
        else
            return notify(error.message, "error");
        }
    }
    
);



