import { createSlice } from "@reduxjs/toolkit";
import { addNewLiquidation, deleteOneLiquidation, editOneLiquidation, getAllLiquidation, getLiquidationForSearch, getNewLiqidationForSalesUser, getliquidationDetails } from "./thunk";

//getLiquidationForSearch
const initialState={
    liquidation:[],
    liquidationDetails:[],
    liquidationSearch:[],
    salesLiquidation:{},
    loading:false,
    DetailsLoading:false
};

const liquidationSlice=createSlice({
    initialState,
    name:'liquidationSlice',
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getAllLiquidation.fulfilled,(state,action)=>{
            state.liquidation=action?.payload;
            state.loading = false;
        });
        builder.addCase(getAllLiquidation.pending, (state) => {
          state.loading = true;
          state.liquidation = [];
         })
        builder.addCase(getliquidationDetails.fulfilled,(state,action)=>{
            state.liquidationDetails=action?.payload;
            state.DetailsLoading = false;
        });
        builder.addCase(getliquidationDetails.pending, (state) => {
          state.DetailsLoading = true;
          state.liquidationDetails = [];
         })
        builder.addCase(addNewLiquidation.fulfilled,(state,action)=>{
            //state.liquidation=[...state.liquidation,action?.payload];
        });
        builder.addCase(getLiquidationForSearch.fulfilled,(state,action)=>{
            state.liquidationSearch=action?.payload;
        });
        builder.addCase(editOneLiquidation.fulfilled, (state, action) => {
            if (Array.isArray(state.liquidation)) {
                state.liquidation = state.liquidation.map(item =>
                    item?._id.toString() === action?.payload?._id.toString()
                        ? { ...item, ...action.payload }
                        : item
                );
            }
        });
        builder.addCase(deleteOneLiquidation.fulfilled,(state,action)=>{
            state.liquidation=state.liquidation.filter(item=>
                item?._id.toString()!=action?.payload?._id.toString()
            )
        });
        builder.addCase(getNewLiqidationForSalesUser.fulfilled,(state,action)=>{
            state.salesLiquidation=action?.payload;
        });

    }
});

export default liquidationSlice.reducer;