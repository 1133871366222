import { createAsyncThunk } from "@reduxjs/toolkit";
import notify from "../../../hook/useNotification";
import { useGetDataToken } from "../../../hooks/useGetData";
import { useUpdateData } from "../../../hooks/useUpdateData";

export const getAllWithDrawsForAccountant=createAsyncThunk(
    "withDrawsSlice/getAllWithDrawsForAccountant",
    async(query)=>{
        try {
            const response=await useGetDataToken(`/api/v1/wallets/withdrawalRequest?${query}`);
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
                else
                return notify(error.response.data,"error");
        }
    }
);

export const updateWithDraw=createAsyncThunk(
    "withDrawsSlice/updateWithDraw",
    async({id,data})=>{
        try {
            console.log('ccccccccccccc',id,data);
            
            const response = await useUpdateData(`/api/v1/wallets/withdrawalRequest/${id}`, data);
            notify("تم  التعديل بنجاح", "success");
            return response;
        } catch (error) {
            if (error.message == "Network Error")
                return notify("حدث خطأ اثناء الاتصال بالانترنت حاول مرة اخري ", "error");
                else
                return notify(error.response.data,"error");
        }
    }
);

export const EmptyWithDraws = createAsyncThunk(
    "withDrawsSlice/EmptyWithDraws",
    ()=>{
        return true
    }
)