import React, { useEffect, useRef, useState } from "react";
import GetAllUsersHook from "../../hook/users/GetAllUsersHook";
import PrintAndDownload from "../Utilities/PrintAndDownload";
import SearchBarComponent from "../Utilities/SearchBarComponent";
import TableItemComponent from "../Utilities/TableItemComponent";
import UsersHeader from "../Utilities/UsersHeader";
import { exportToExcel } from "../Utilities/exportToExcel";
import { Spinner } from "react-bootstrap";
import Pagination from "../Utilities/Pagination";
import {
  getAllUsers,
  getAllUsersWithPagination,
} from "../../redux/slices/users/thunk";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Spinner as BootstrapSpinner } from "react-bootstrap"; // Import Bootstrap Spinner

export default function AllUsersComponent({ hasAdd }) {
  const [pageCount, setPageCount] = useState(0);
  const [selectedPermission, setSelectedPermission] = useState(0);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(0);
  const [serialNumber, setSerialNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const [handleSearch, users, permissionGroups, loading] = GetAllUsersHook();
  // console.log(loading);
  useEffect(() => {
    if (users?.data) {
      setPageCount(users?.num_of_pages);
    }
  }, [users]);

  const nextPage = async (page) => {
    let next_page = page;
    if (searchParams.get("page") == null || searchParams.get("page") == 0) {
      //console.log('pppppppppppppppppp')
      searchParams.set("page", 1);
      setSearchParams(searchParams);
    } else {
      page = searchParams.get("page");
      searchParams.set("page", next_page);
      setSearchParams(searchParams);
    }
    let query = `?page=${next_page}`;

    // // مندوب
    // if (loggedUser && !loggedUser.isAdmin) {
    //   query += `&father_id=${loggedUser._id}`;
    //   //محاسب
    //   // if(!loggedUser.liquidation_user && !loggedUser.isAdmin) query=``;
    // }
    // // مسوق
    // if (loggedUser?.userMarketer && !loggedUser.liquidation_user && !loggedUser.isAdmin) {
    //   query += `&father_id=${loggedUser.father_id}`;
    // }

    if (selectedPermission) query += `&permissions=${selectedPermission}`;
    if (fullName) query += `&fullname=${fullName}`;
    if (email) query += `&email=${email}`;
    if (status) query += `&status=${status}`;
    if (serialNumber) query += `&serialNumber=${serialNumber}`;

    // مندوب
    if (loggedUser.liquidation_user) query += `&father_id=${loggedUser._id}`;
    // مسوق
    if (loggedUser.userMarketer) query += `&father_id=${loggedUser.father_id}`;

    dispatch(getAllUsersWithPagination(query));
  };

  const tableRef = useRef(null);

  // if (permissionGroups?.length === 0) return <Spinner />; // Return null or loader until permissionGroups are loaded

  const loggedUser = JSON.parse(localStorage.getItem("user"));
  console.log("loggedUser",loggedUser)
  const handleExport = () => {
    exportToExcel(users?.data, "users");
  };
  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write("<style>");
    printWindow.document.write(`
      /* Add your CSS styles here */
      body {
        font-family: Arial, sans-serif;
      }
      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }
      th, td {
        border: 1px solid #dddddd;
        padding: 8px;
        text-align: left;
      }
      .table-header {
        background-color: #f2f2f2;
      }
      tr:nth-child(even) {
        background-color: #f9f9f9;
      }
    `);
    printWindow.document.write("</style>");
    printWindow.document.write("</head><body>");
    printWindow.document.write("<table>");

    // Table headers
    printWindow.document.write("<tr>");
    printWindow.document.write("<th class='table-header'>رقم المعرف</th>");
    printWindow.document.write("<th class='table-header'>الاسم الثلاثي</th>");
    printWindow.document.write(
      "<th class='table-header'>البريد الالكتروني</th>"
    );
    printWindow.document.write("</tr>");

    // Table data
    users?.data.forEach((user) => {
      printWindow.document.write("<tr>");
      printWindow.document.write(`<td>${user?.serialNumber}</td>`);
      printWindow.document.write(`<td>${user?.fullname}</td>`);
      printWindow.document.write(`<td>${user?.email}</td>`);
      printWindow.document.write("</tr>");
    });

    printWindow.document.write("</table>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  const handleDownload = () => {
    let csvContent = "رقم المعرف,الاسم الثلاثي,البريد الالكتروني\n";
    users?.data.forEach((user) => {
      csvContent += `${user?.serialNumber},${user?.fullname},${user?.email}\n`;
    });
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "users.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div>
      {hasAdd && !loggedUser?.is_accountant && (
        // (loggedUser.liquidation_user || loggedUser.isAdmin) &&
        <UsersHeader title={"اضافة مستخدم جديد"} navigateTo={"/users/add"} />
      )}

      <div className="usersSearchBox">
        <div className="d-flex justify-content-between" style={{ gap: "20px" }}>
          <div className="title">محرك البحث</div>
          <PrintAndDownload
            onPrint={handlePrint}
            onDownload={handleDownload}
            download_excel={handleExport}
            hasExcelDownload={true}
          />
        </div>
        <SearchBarComponent
          fullName={fullName}
          setFullName={setFullName}
          selectedPermission={selectedPermission}
          setSelectedPermission={setSelectedPermission}
          email={email}
          setEmail={setEmail}
          status={status}
          setStatus={setStatus}
          serialNumber={serialNumber}
          setSerialNumber={setSerialNumber}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </div>

      <div className="">
        <div ref={tableRef}>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <BootstrapSpinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </BootstrapSpinner>
            </div>
          ) : (
            permissionGroups &&
            users?.data?.filter(
              (item) =>
                (loggedUser?.isAdmin || loggedUser?.is_accountant) ? !item?.father_id : item
            )?.map((item, i) => (
              <div
                key={i}
                className="usersSearchBox"
                style={{ overflow: "hidden" }}
              >
                <TableItemComponent
                  key={i}
                  user={item}
                  permissionGroups={permissionGroups}
                />
              </div>
            ))
          )}
        </div>
      </div>
      <div>
        <Pagination onPress={nextPage} pageCount={pageCount} />
      </div>
    </div>
  );
}
